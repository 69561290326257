import React from "react";
import "./DashNavigation.css";
import navLogo from "../../../assets/profits.svg";
import { AiFillHome, AiOutlineLogout } from "react-icons/ai";
import { PiWallet } from "react-icons/pi";
import { FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { MdHistory } from "react-icons/md";

const DashNavigation = ({ pathname }) => {
  const navigate = useNavigate();
  return (
    <>
      <section className="dash_navigation_sect">
        <div className="navigation_logo">
          <img src={navLogo} alt="" />
        </div>
        <div className="dashboard_navigation_links">
          <div onClick={() => navigate("/wallet")}>
            <AiFillHome
              color={pathname === "/wallet" ? "#162af1" : "#1464b8"}
              size={22}
            />
            <p
              style={{
                color: `${pathname === "/wallet" ? "#162af1" : "#1464b8"}`,
              }}
            >
              Home
            </p>
          </div>
          <div onClick={() => navigate("/account")}>
            <PiWallet
              color={pathname === "/account" ? "#162af1" : "#1464b8"}
              size={22}
            />
            <p
              style={{
                color: `${pathname === "/account" ? "#162af1" : "#1464b8"}`,
              }}
            >
              Wallet
            </p>
          </div>
          <div onClick={() => navigate("/settings")}>
            <FiSettings
              color={pathname === "/settings" ? "#162af1" : "#1464b8"}
              size={22}
            />
            <p
              style={{
                color: `${pathname === "/settings" ? "#162af1" : "#1464b8"}`,
              }}
            >
              User
            </p>
          </div>
        </div>
        <div
          className="logout_sect"
          onClick={() => {
            sessionStorage.removeItem("accessToken");
            if (!sessionStorage.getItem("accessToken")) {
              navigate("/auth/login");
            }
          }}
        >
          <AiOutlineLogout color={"#1464b8"} size={22} />
          <p style={{ color: "#1464b8" }}>Logout</p>
        </div>
      </section>
      <section className="mobile_dashboard">
        <div onClick={() => navigate("/wallet")}>
          <AiFillHome
            color={pathname === "/wallet" ? "#162af1" : "#1464b8"}
            size={22}
          />
          <p
            style={{
              color: `${pathname === "/wallet" ? "#162af1" : "#1464b8"}`,
            }}
          >
            Home
          </p>
        </div>
        <div onClick={() => navigate("/account")}>
          <PiWallet
            color={pathname === "/account" ? "#162af1" : "#1464b8"}
            size={22}
          />
          <p
            style={{
              color: `${pathname === "/account" ? "#162af1" : "#1464b8"}`,
            }}
          >
            Wallet
          </p>
        </div>

        <div onClick={() => navigate("/transactions")}>
          <MdHistory
            color={pathname === "/transactions" ? "#162af1" : "#1464b8"}
            size={25}
          />
          <p
            style={{
              color: `${pathname === "/transactions" ? "#162af1" : "#1464b8"}`,
            }}
          >
            Transactions
          </p>
        </div>
        <div onClick={() => navigate("/settings")}>
          <FiSettings
            color={pathname === "/settings" ? "#162af1" : "#1464b8"}
            size={22}
          />
          <p
            style={{
              color: `${pathname === "/settings" ? "#162af1" : "#1464b8"}`,
            }}
          >
            Profile
          </p>
        </div>
        <div
          className="logout_sect"
          onClick={() => {
            sessionStorage.removeItem("token");
            if (!sessionStorage.getItem("token")) {
              navigate("/auth/login");
            }
          }}
        >
          <AiOutlineLogout color={"#1464b8"} size={22} />
          <p style={{ color: "#1464b8" }}>Logout</p>
        </div>
      </section>
    </>
  );
};

export default DashNavigation;
