import React from "react";
import "./Privacy.css";
import "../Terms/Terms.css";
import { Footer, Header } from "../../components";

const Privacy = () => {
  const name = "Digital Shield Finance";

  return (
    <>
      <Header />
      <div className="terms">
        <p className="sub-p">
          {name} is a financial institution that takes privacy and security very
          seriously. As such, they have implemented a robust privacy policy to
          protect the personal information of their users. The company collects
          only the minimum necessary information required to provide banking
          services to its users, and this information is stored securely in
          accordance with applicable laws and regulations.
        </p>
        <p className="sub-p">
          <span>Privacy Statement:</span> At {name}, we are committed to
          safeguarding your privacy. We collect and process your personal
          information in accordance with applicable data protection laws and
          regulations. Our Privacy Policy outlines the types of data we collect,
          how we use it, and the measures we take to protect your information.
        </p>
        <p className="sub-p">
          <span>Data Collection:</span> We may collect personal information such
          as your name, contact details, and identification documents during the
          account registration and verification process. Additionally, we may
          collect data related to your cryptocurrency transactions and account
          activity for security and regulatory compliance purposes.
        </p>
        <p className="sub-p">
          {name} recognizes that the privacy and security landscape is
          constantly evolving. As such, the company regularly updates its
          privacy policy to reflect changes in laws, regulations, and industry
          best practices. Users are encouraged to review the privacy policy
          periodically to stay informed of any changes that may affect their
          personal information.
        </p>
        <p className="sub-p">
          In the event of a data breach or other security incident, {name} has
          established procedures to quickly and effectively respond to the
          situation. This includes notifying affected customers and regulatory
          authorities as required by law, and taking steps to mitigate any
          potential harm to affected individuals.
        </p>
        <p className="sub-p">
          <span>Data Usage:</span> We use your personal information to provide
          our services, including processing your cryptocurrency transactions,
          verifying your identity, and ensuring the security of your account. We
          may also use your data to communicate important updates, promotions,
          and other relevant information with your consent.
        </p>
        <p className="sub-p">
          <span>Data Security:</span> We employ industry-standard security
          measures to protect your data from unauthorized access, disclosure,
          alteration, and destruction. Our security protocols include
          encryption, firewalls, and regular security audits. However, it's
          crucial for users to take responsibility for their account security by
          safeguarding their login credentials.
        </p>
        <p className="sub-p">
          <span>Data Disclosure:</span> We do not sell or rent your personal
          information to third parties. However, we may share your data with
          trusted third-party service providers for purposes such as identity
          verification, fraud prevention, and customer support. We may also
          disclose your information when required by law or to comply with legal
          processes.
        </p>
        <p className="sub-p">
          These privacy and policy statements are provided as a general
          guideline and may need to be customized to meet the specific
          requirements of {name} and adhere to relevant data protection laws in
          your jurisdiction. It's essential to consult with legal professionals
          to ensure that your privacy policy is comprehensive and legally
          compliant.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
