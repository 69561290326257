import React, { useState } from "react";
import "./Header.css";
import { RxHamburgerMenu } from "react-icons/rx";
import Nav from "../Nav/Nav";
import logoText from "../../assets/pro.svg";
import { Link } from "react-router-dom";

const Header = () => {
  const name = "Digital Shield Finance";
  const [tab, setTab] = useState(false);

  const toggleNav = () => {
    setTab(!tab);
  };
  return (
    <header className="header">
      <div className="header_item_one">
        <RxHamburgerMenu
          className="menu"
          onClick={toggleNav}
          size={24}
          color="#fff"
        />
        <Link to={"/"}>
          <img src={logoText} alt="" />
        </Link>
      </div>
      <Nav name={name} tab={tab} toggleNav={toggleNav} />
    </header>
  );
};

export default Header;
