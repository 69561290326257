import React from "react";
import { Footer, Header } from "../../components";
import "./Terms.css";

const Terms = () => {
  const name = "Digital Shield Finance";
  return (
    <>
      <Header />
      <div className="terms">
        <p className="sub-p">
          <span>Account Registration and Verification:</span> Users must provide
          accurate and up-to-date information during the registration process.
          {name} reserves the right to verify user identities and may request
          additional documentation for this purpose. Failure to provide accurate
          information or comply with verification requests may result in account
          suspension or closure.
        </p>
        <p className="sub-p">
          <span>Risk Acknowledgment:</span> Users acknowledge that
          cryptocurrency trading involves significant volatility and risks.
          {name} provides educational resources, but users are responsible for
          their trading decisions. Users should only invest funds they can
          afford to lose and should seek independent financial advice if needed.
        </p>
        <p className="sub-p">
          <span>Security and Password Protection:</span> Users are responsible
          for maintaining the confidentiality of their account login
          credentials. They should immediately report any unauthorized access or
          suspicious activity to {name}. The platform will not be liable for any
          losses resulting from unauthorized access to user accounts due to
          negligence.
        </p>
        <p className="sub-p">
          <span>Fees and Commissions:</span> {name} may charge fees and
          commissions for its services, and these are outlined in the fee
          schedule on the website. Users agree to pay all applicable fees
          associated with their transactions. Fee rates may be subject to
          change, and users will be notified accordingly.
        </p>
        <p className="sub-p">
          <span>Compliance with Legal and Regulatory Requirements:</span> Users
          must comply with all applicable laws and regulations governing
          cryptocurrency trading and financial activities in their jurisdiction.
          {name} will cooperate with legal authorities and may disclose user
          information if required by law. Users agree not to use the platform
          for any illegal or fraudulent activities.
        </p>
        <p className="sub-p">
          Another important aspect of {name} terms and conditions is its privacy
          policy. This policy outlines how the website collects, uses, and
          protects user information. It also outlines how users can access and
          modify their personal information. By agreeing to the privacy policy,
          users agree to allow the website to collect and use their personal
          information in accordance with the policy.
        </p>
        <p className="sub-p">
          {name} terms and conditions also cover topics such as intellectual
          property, limitations of liability, and dispute resolution. These
          sections provide important information regarding the website's
          policies on copyright and trademark infringement, as well as its
          liability in the event of any damages or losses incurred by users.
          They also outline the procedures for resolving disputes between the
          website and its users.
        </p>
        <p className="sub-p">
          In addition to its general terms and conditions, {name}
          has specific terms and conditions for its banking, investment, and
          lending services. These sections provide detailed information
          regarding the terms and conditions specific to each service. users
          should carefully review these sections before using any of the
          website's services.
        </p>
        <p className="sub-p">
          These terms and conditions are provided as a general guideline and may
          need to be customized to meet the specific requirements of
          {name} and adhere to relevant legal regulations in your jurisdiction.
          It's essential to consult with legal professionals to ensure that your
          terms and conditions are comprehensive and legally sound.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
