import React from "react";
import "./About.css";
import { Header, Footer, Button, Hero } from "../../components";
import team from "../../assets/team.webp";
import teamtwo from "../../assets/team1.webp";
import { FaCheck } from "react-icons/fa";
import forex from "../../assets/trading.webp";
import vision from "../../assets/vision.jpg";
import vision2 from "../../assets/vision2.jpg";
import Motion from "../Motion";
import MotionRight from "../MotionRight";
import MotionX from "../MotionX";
import { useNavigate } from "react-router-dom";

const About = () => {
  const name = "Digital Shield Finance";
  const navigate = useNavigate();

  return (
    <section className="about_sect">
      <Header />
      <Hero paragraph={"Who we are"} title={"About us"} />
      <div className="about_intro_sect">
        <div className="about_intro_image_sect">
          <Motion>
            <img src={team} alt="" loading="lazy" />
          </Motion>
        </div>
        <div className="about_intro_text_sect">
          <MotionRight>
            <h1 className="bold_header_text home_title">More About Us</h1>
          </MotionRight>
          <MotionX>
            <p>
              {name} International Investment Corporate Company Limited is a
              very experienced and promising organization in the field of
              trustee administration and long-haul ventures. The joining of
              distinctive systems and techniques add to income, gainful
              collaboration, and organized advancement. The most created
              territory of {name}
              International Investment Corporate Company Limited movement is
              multicurrency trading on the Forex market.
            </p>
          </MotionX>
          <Motion>
            <p>
              It was established by a group of professional traders and
              investors, who have fore seen the future of International Capital
              Market
            </p>
          </Motion>
          <MotionX>
            <p>
              Since 2018, we offer the best conditions for financial specialists
              from Great England and will be prepared to see you among them.
              Various organization workers are proficient money related
              investigators and experienced specialists in remote trade trading
              and hypothesis with securities and shares of various UK
              organizations. They have all the information and abilities that
              are important to be required in beneficial trade and expand the
              benefit with sensible risk
            </p>
          </MotionX>
        </div>
      </div>
      <div
        style={{ flexDirection: "row-reverse", paddingTop: 0 }}
        className="about_intro_sect"
      >
        <div className="about_intro_image_sect">
          <MotionRight>
            <img src={vision} alt="" loading="lazy" />
          </MotionRight>
        </div>
        <div className="about_intro_text_sect">
          <Motion>
            <h1 className="bold_header_text home_title">Why Cryptocurrency</h1>
          </Motion>
          <MotionX>
            <p>
              The way people use and think about money is constantly evolving.
            </p>
          </MotionX>
          <MotionRight>
            <p>
              The existing financial system was built for a non-digital age,
              ignoring the needs of the modern individual and with many
              unnecessary inefficiencies and gatekeepers. The world now has
              access to new technologies like decentralised cryptocurrencies
              that is quickly making these old systems and ways of thinking
              redundant. Like communication evolving from landlines to mobile
              phones, or post to email, money is now finally catching up with
              other information revolutions.
            </p>
          </MotionRight>
          <MotionX>
            <p>
              Decentralised cryptocurrencies are enabling us to reimagine the
              financial system to upgrade the world to something better.
            </p>
          </MotionX>
        </div>
      </div>
      <div className="about_security_sect">
        <Motion>
          <h1 className="home_title bold_header_text">Security Comes First</h1>
        </Motion>
        <MotionRight>
          <p className="about_p_text">
            Your security and trust are important to us. We're committed to
            protecting your account with the highest standards of security
            available.
          </p>
        </MotionRight>
        <div className="about_security_sect_flex">
          <div className="about_security_sect_flex_image">
            <MotionX>
              <img src={teamtwo} alt="" loading="lazy" />
            </MotionX>
          </div>
          <div className="about_security_sect_flex_text">
            <div className="who_we_are_item_one">
              <div className="who_we_are_item_circle">
                <h3>1</h3>
              </div>
              <div className="who_we_are_item_text">
                <Motion>
                  <h3>We are Trusted</h3>
                </Motion>
                <MotionRight>
                  <p>
                    Over $55B and counting - More than 175,000 people trust over
                    $55 billion with {name} companies.
                  </p>
                </MotionRight>
              </div>
            </div>
            <div className="who_we_are_item_one">
              <div className="who_we_are_item_circle">
                <h3>2</h3>
              </div>
              <div className="who_we_are_item_text">
                <MotionRight>
                  <h3>Strong Encryption</h3>
                </MotionRight>
                <Motion>
                  <p>
                    Everything is encrypted - We use state-of-the-art data
                    encryption when handling your financial information.
                  </p>
                </Motion>
              </div>
            </div>
            <div className="who_we_are_item_one">
              <div className="who_we_are_item_circle">
                <h3>3</h3>
              </div>
              <div className="who_we_are_item_text">
                <MotionX>
                  <h3>Powerful backing</h3>
                </MotionX>
                <Motion>
                  <p>
                    Powerful backing - {name} is backed by $1B in investment
                    from some of the world's largest financial institutions.
                  </p>
                </Motion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ flexDirection: "row-reverse" }}
        className="about_intro_sect"
      >
        <div className="about_intro_image_sect">
          <Motion>
            <img src={vision2} alt="" loading="lazy" />
          </Motion>
        </div>
        <div className="about_intro_text_sect">
          <MotionRight>
            <h1 className="bold_header_text home_title">
              A Culture around Vision
            </h1>
          </MotionRight>
          <MotionX>
            <p>
              We believe that decentralised cryptocurrencies like Bitcoin and
              Ethereum will fundamentally change how the world views and uses
              money. As early pioneers in this industry, we feel it’s our duty
              to bring this knowledge and excitement to the world as best we
              can.
            </p>
          </MotionX>
          <MotionRight>
            <p>
              It takes a certain breed of person, with a special system of
              values, to take on a challenge of such epic proportions.
            </p>
          </MotionRight>
          <Button
            title={"Sign In"}
            width={200}
            height={50}
            background={"#001e88"}
            color={"white"}
            action={() => navigate("/auth/login")}
          />
        </div>
      </div>
      <div className="about_us_what_we_do">
        <Motion>
          <h1 className="home_title bold_header_text">What We Do</h1>
        </Motion>
        <MotionRight>
          <p className="about_p_text">
            We’re building a new standard for crypto trading. One account, one
            balance, streamlined access to multiple liquidity venues.
          </p>
        </MotionRight>
        <div className="about_security_sect_flex">
          <div className="forex_text">
            <Motion>
              <h3>Investment</h3>
            </Motion>
            <MotionX>
              <p>
                {name} redefines the way we live; from "being smart" to "being
                wise". Our vision is to build a strong alliance and lasting
                partnership with our investors. A platform connecting
                individuals to create a community based on technology.
              </p>
            </MotionX>
            <Motion>
              <p>
                {name} as an investment project does not operate like the
                banking system nor HYIP. Funds deposited are allocated to
                different sectors of the company's Investment strategy.
              </p>
            </Motion>
            <Motion>
              <h3 style={{ marginTop: 35 }}>Trading</h3>
            </Motion>
            <MotionX>
              <div className="check_flex">
                <FaCheck color="#2639f2" size={20} />
                <p>Investors receive their earnings daily.</p>
              </div>
            </MotionX>
            <MotionX>
              <div className="check_flex">
                <FaCheck color="#2639f2" size={20} />
                <p>
                  Withdrawals are made at intervals and you have the option to
                  reinvest after the completion of your trading cycle.
                </p>
              </div>
            </MotionX>
            <Motion>
              <div className="check_flex">
                <FaCheck color="#2639f2" size={20} />
                <p>We also offer consultancy on Trading strategy.</p>
              </div>
            </Motion>
            <Motion>
              <div className="check_flex">
                <FaCheck color="#2639f2" size={20} />
                <p>4.5% For $100,000 and above.</p>
              </div>
            </Motion>
          </div>
          <div className="forex_sect">
            <img src={forex} alt="" loading="lazy" />
            <div className="forex_text_sect">
              <MotionRight>
                <h3>Always Available</h3>
              </MotionRight>
              <MotionRight>
                <p>
                  Our desktop, web, and mobile platforms put powerful tools into
                  the hands of traders and investors – helping to level the
                  playing field in the competitive world of trading and
                  investing.
                </p>
              </MotionRight>
              <MotionX>
                <div className="check_flex">
                  <FaCheck color="#2639f2" size={20} style={{ width: "20%" }} />
                  <p style={{ width: "80%" }}>
                    Utilize real-time market data and order execution
                    technology.
                  </p>
                </div>
              </MotionX>
              <MotionX>
                <div className="check_flex">
                  <FaCheck color="#2639f2" size={20} style={{ width: "20%" }} />
                  <p style={{ width: "80%" }}>
                    Access multiple Investment classes, including Crypto, Stocks
                    and Options.
                  </p>
                </div>
              </MotionX>
              <MotionRight>
                <div className="check_flex">
                  <FaCheck color="#2639f2" size={20} style={{ width: "20%" }} />
                  <p style={{ width: "80%" }}>
                    Our trading systems have a 99.999% uptime accuracy.
                  </p>
                </div>
              </MotionRight>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default About;
