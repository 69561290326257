import React from "react";
import "./Proof.css";
import { Footer, Header, Hero } from "../../components";
import MotionX from "../MotionX";
import Motion from "../Motion";
import MotionRight from "../MotionRight";

const Proof = () => {
  const name = "Digital Shield Finance";

  return (
    <section className="proof_section">
      <Header />
      <Hero
        title={"Proof of Reserves"}
        paragraph={"Full verification of customers' cryptocurrency"}
      />
      <div className="proof_texts">
        <MotionX>
          <p>
            {name} has always gone the extra mile to keep customers safe, with
            industry-leading processes protecting all our customers’ assets.
          </p>
        </MotionX>
        <Motion>
          <p>You don’t need to just take our word for it though.</p>
        </Motion>
        <MotionRight>
          <p>
            {name} is proud to be blazing a trail for safe cryptocurrency
            investment by having all the assets we store regularly verified by
            independent auditors Mazars, so you can be confident all the assets
            you hold with
            {name} are exactly where they should be.
          </p>
        </MotionRight>
      </div>
      <div className="proof_texts">
        <Motion>
          <h3 className="bold_header_text">So, what is it?</h3>
        </Motion>
        <MotionRight>
          <p>
            <span>Mazars</span> is a leading international audit, tax and
            advisory firm. They run a series of procedures every quarter to make
            sure that the Bitcoin, Ethereum and any other cryptocurrency you see
            in your Luno Wallet exists and is exactly where we say it is.
          </p>
        </MotionRight>
        <MotionX>
          <p>
            This report provides current and prospective customers with
            reassurance that their digital assets actually exist and that the
            funds are either situated on-chain or within a custody solution.
          </p>
        </MotionX>
      </div>
      <div className="proof_texts">
        <MotionRight>
          <h2 className="bold_header_text">Why does this matter?</h2>
        </MotionRight>
        <Motion>
          <p>
            Proof of reserves reports bring a level of trust to an industry that
            some people believe lacks transparency. It plays a valuable role in
            creating a safer investment environment for all cryptocurrency
            customers and is an important step in achieving our mission of
            putting the power of crypto in everyone’s hands.
          </p>
        </Motion>
        <MotionX>
          <p>Marius Reitz, GM of {name} said:</p>
        </MotionX>
        <MotionRight>
          <p>
            "We take a stand against crypto crime and proof of reserves helps to
            protect all stakeholders. {name}’s ten million customers in more
            than 40 countries can rest assured that their wallets do in fact
            contain the crypto stated. We have always said that {name} is a safe
            platform to secure and store crypto and this is external validation
            that this is in fact the case."
          </p>
        </MotionRight>
      </div>
      <Footer />
    </section>
  );
};

export default Proof;
