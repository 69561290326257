import { Route, Routes, useLocation } from "react-router-dom";

import {
  About,
  Dashboard,
  Exchange,
  ForgetPassword,
  Home,
  Login,
  Proof,
  Register,
  ResetPassword,
} from "./apps";
import { useEffect, useState } from "react";
import { Privacy, Terms } from "./components";
import { pops } from "./utils/data";
import { ToastContainer, toast } from "react-toastify";

function App() {
  const { pathname } = useLocation();
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      if (counter === pops.length) {
        setCounter(1);
      } else {
        setCounter(counter + 1);
      }
    }, 60000);
    toast.success(
      `${pops[counter]?.name} just ${pops[counter]?.transaction} ${pops[counter]?.amount}$`,
      { theme: "dark" }
    );
  }, [counter]);

  return (
    <>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Exchange />} path="/exchange" />
        <Route element={<About />} path="/about" />
        <Route element={<Proof />} path="/proof-of-reserve" />
        <Route element={<Login />} path="/auth/login" />
        <Route element={<Register />} path="/auth/register" />
        <Route element={<ResetPassword />} path="/auth/forget" />
        <Route element={<ForgetPassword />} path="/auth/reset/:id" />
        <Route element={<Terms />} path="/terms" />
        <Route element={<Privacy />} path="/privacy" />
        <Route element={<Dashboard />} path="*" />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
